import React from 'react'
import Img from "gatsby-image"

const PageHeading = ({title, imageData}) => {
    const headSource = [
        imageData.big,
        {
            ...imageData.medium,
            media: `(max-width: 62.5em) and (min-width: 40em)`
        },
        {
            ...imageData.small,
            media: `(max-width: 40em)`
        },
    ]
    return (

       <div className="page-heading">
           <div className="image-overlay">
                <div className="heading-holder">
                    <div className="heading-line"></div>
                    <h1>{title}</h1>
                </div>
                
           </div>
           <Img 
                fluid={headSource}
                quality='100'
                objectFit="cover"
                objectPosition="50% 50%"
                style={{
                    width:'100%',
                    display:'block',
                    position: 'absolute',
                    height: '100%'
                }}              
        
            />
       </div>
       
    )

}

export default PageHeading
import React from 'react'
import Img from "gatsby-image"

const ContentImage = ({image, alt}) => {

    const imageSource = [
        image.big,
        {
            ...image.medium,
            media: `(max-width: 62.5em) and (min-width: 50em)`
        },
        {
            ...image.small,
            media: `(max-width: 50em)`
        },
    ]

    return (
        <div className="image">
            <Img 
                fluid={imageSource}
                alt={alt}
                quality='100'
                objectFit="cover"
                objectPosition="50% 50%"
                style={{
                    width:'100%',
                    display:'block',
                    position: 'absolute',
                    height: '100%',
                    top: '0'
                }}              

            />
       </div>
    )

}

export default ContentImage
import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import ContentImage from '../components/contentimage'

const RecladsPage = ({data}) => (

    <Layout pageClass="info-page reclads-page">
    <SEO title="Reclads" description="Modus has expertise in reclads and weathertightness projects over many years." />
    <PageHeading title="Reclads" imageData={data.headerImage} />
    <main className="main">
        <div>
        <div className="typography inner reverse">

            <div className="text-holder">
                <p>
                    Modus Developments has expertise in reclads and weathertightness projects, gained from a number of successful remediation projects over the years. From stand-alone homes to townhouse complexes, we can remove the stigma of a dated look, and upgrade the performance of buildings that need a second chance.
                </p>

                <p>
                    Reclad projects inevitably call for strong project management and meticulous attention to detail. Our experienced team will work within your budget and deliver the assurance you need. We know this is your chance to add serious value, so we work hard to deliver it for you.
                </p>

                <p className="last">
                    Owners often take the opportunity of a recladding project to update features or remodel areas unrelated to weathertightness – for instance, a new kitchen, paint job and landscaping. We can help you manage this efficiently, by building your upgrade ideas into the overall programme and delivering them cost-effectively.
                </p>
            </div>
            <ContentImage image={data.contentImage} alt="Entrance to house" />
        </div>
        </div>
    </main>
    </Layout>

)
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "_43A2123-Pano.jpg"}}) { 
            ...ResponsiveImage
        }
        contentImage: imageSharp(resolutions: {originalName: {eq: "_43A2118.jpg"}}) { 
            big: fluid (maxWidth: 630, maxHeight: 545, quality: 100, cropFocus: NORTH) {
                ...GatsbyImageSharpFluid
            }
            medium: fluid (maxWidth: 435, maxHeight: 705, quality: 100, cropFocus: NORTH) {
                ...GatsbyImageSharpFluid
            }
            small: fluid (maxWidth: 720, maxHeight: 540, quality: 100, cropFocus: NORTH) {
                ...GatsbyImageSharpFluid
            }
        }
    
    }
`

export default RecladsPage